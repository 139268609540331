<template>
  <v-app id="app">
    <v-main>
      <v-card>
        <v-card-title>
          <v-row no-gutters justify="center">
            <v-col class="text-center">
              {{ $t("agenda") }} {{ this.$store.state.currentSession.agenda }}
            </v-col>
            <v-col class="text-center">
              <span class="mr-2">
                <v-icon>mdi-circle-slice-8</v-icon>
                {{ $t("total") }}
                :
                <strong>{{ presentStat[0] }}</strong
                >;
              </span>
              <span class="mr-2">
                <v-icon>mdi-circle-slice-5</v-icon>
                {{ $t("23maj") }}
                :
                <strong>{{ presentStat[1] }}</strong
                >;
              </span>
              <span class="mr-2">
                <v-icon> mdi-circle-slice-4</v-icon>
                {{ $t("12maj") }}
                :
                <strong>{{ presentStat[2] }}</strong
                >;
              </span>
              <span class="mr-2">
                <v-icon> mdi-circle-slice-1</v-icon>
                {{ $t("15maj") }}
                :
                <strong>{{ presentStat[3] }}</strong
                >;
              </span>
            </v-col>
            <div></div>
          </v-row>
        </v-card-title>
      </v-card>
      <div>
        <router-view></router-view>
      </div>
    </v-main>
    <v-bottom-navigation :value="activeBtn" color="primary lighten-1" grow app>
      <v-btn to="/setup">
        <span>{{ $t("setup") }} </span>
        <v-icon>mdi-tune</v-icon>
      </v-btn>
      <v-btn to="/rollCall">
        <span>{{ $t("roll-call") }} </span>
        <v-icon>mdi-gavel</v-icon>
      </v-btn>
      <v-btn to="/gsl">
        <span>
          {{ $t("gsl") }}
        </span>
        <v-icon>mdi-format-list-checkbox</v-icon>
      </v-btn>
      <v-btn to="/motion">
        <span>
          {{ $t("motion") }}
        </span>
        <v-icon>mdi-hand-left</v-icon>
      </v-btn>
      <v-btn to="/mod">
        <span>
          {{ $t("mod") }}
        </span>
        <v-icon>mdi-message-outline</v-icon>
      </v-btn>
      <v-btn to="/unmod">
        <span>
          {{ $t("unmod") }}
        </span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn to="/speaker">
        <span>
          {{ $t("speaker") }}
        </span>
        <v-icon>mdi-microphone</v-icon>
        <!-- <v-icon> mdi-account-voice</v-icon> -->
      </v-btn>
      <v-btn to="/vote">
        <span> {{ $t("vote") }} </span>
        <v-icon>mdi-vote</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "Home",
  data() {
    return {
      activeBtn: null,
      agendaDialog: false,
      rollCallDialog: false,
      agenda: "",
      fullscreen: false,
    };
  },
  watch: {
    currentSession: {
      deep: true,
      handler() {
        this.updateSession();
      },
    },
  },
  computed: {
    currentSession() {
      return this.$store.state.currentSession;
    },
    inSessionCountries() {
      return this.$store.getters.sortedInSessionCountries;
    },
    presentStat() {
      return this.$store.getters.presentStat;
    },
  },
  methods: {
    updateSession: debounce(function () {
      this.$store.dispatch("updateSession");
    }, 2000),
    saveAgenda() {
      this.$store.commit("setAgenda", this.agenda);
      this.agendaDialog = false;
    },
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    changeStatus(item, status) {
      const payload = {
        item: item,
        status: status,
      };
      this.$store.commit("setStatus", payload);
    },
  },
  created() {
    this.agenda = this.$store.state.currentSession.agenda;
  },
};
</script>
<i18n>
{
  "en": {
    "setup":"Setup",
    "agenda": "Agenda: ",
    "total": "Total",
    "23maj": "2/3 Majority",
    "12maj": "Simple Majority",
    "15maj": "20%",
    "roll-call": "Roll Call",
    "motion": "Motion",
    "gsl": "GSL",
    "mod": "Mod",
    "vote": "Vote",
    "speaker": "Single Speaker",
    "unmod": "Unmod"
  },
  "zh": {
    "setup":"委员会设置",
    "roll-call": "点名",
    "total": "出席总数",
    "23maj": "2/3多数",
    "12maj": "简单多数",
    "15maj": "20%",
    "agenda": "议程： ",
    "motion": "动议",
    "gsl": "主发言名单",
    "mod": "有组织核心磋商",
    "vote": "投票",
    "speaker": "自定义发言",
    "unmod": "无组织核心磋商"

  }
}
</i18n>
g